import { Injectable } from '@angular/core';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { PageSize } from 'src/app/common/GridPageSize';
@Injectable({
  providedIn: 'root'
})

export class CommonPageService {

  commonState: State = {
    skip: 0,
    take: PageSize.Size,
    sort: [],
    filter: null,
  };
  
  groupPaginationListDataCommon: GridDataResult;
  public clearGroupPaginationListDataCommon() {
    this.groupPaginationListDataCommon = {
      data: [],
      total: 0
    }
  }

  public setGroupPaginationListDataCommon(data: any, total: number) {
    this.groupPaginationListDataCommon = {
      data: data,
      total: total
    }
  }

  public clearCommonState() {
    this.commonState = {
      skip: 0,
      take: PageSize.Size,
      sort: [],
      filter: null,
    };
  }

  public getCustomPageModel(
    statusId: number = 0, 
    facilityId: number = 0, 
    priorityId: number = 0, 
    thirdPartyId: number = 0, 
    isRecon: number = 0, 
    departmentId: number = 0, 
    documentTypeId: number = 0,
    patientId: number = 0,
   ): any {
    return {
      departmentId: departmentId,
      documentTypeId: documentTypeId,
      facilityId: facilityId,
      statusId: statusId,
      priorityId: priorityId,
      thirdPartyId: thirdPartyId,
      isRecon: isRecon,
      patientId: patientId,
      state: {
        skip: this.commonState.skip,
        take: this.commonState.take,
        sort: this.commonState.sort,
        filter: this.commonState.filter != null ? this.commonState.filter?.filters : [],
      }
    }
  }

  public exportToPDF(gridComponent: GridComponent): void {
    gridComponent.saveAsPDF();
  }
  
  public exportToExcel(gridComponent: GridComponent): void {
    gridComponent.saveAsExcel();
  }
}
