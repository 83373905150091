import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { CoreHttpService } from 'src/app/providers/core-http/core-http.service';
import { environment } from 'src/environments/environment';
import { DropdownListRequestModel, DropdownListResponseModel } from './administration.classes ';

@Injectable({
  providedIn: 'root'
})
export class AdministrationService extends CoreHttpService {

  private userUrl: string = `${environment.apiUrl}/user`;
  private roleUrl: string = `${environment.apiUrl}/role`;
  private teamUrl: string = `${environment.apiUrl}/team`;
  private commonUrl: string = `${environment.apiUrl}/common`;
  private CommonUrl: string = `${environment.apiUrl}/Common`;
  private facilityUrl: string = `${environment.apiUrl}/FacilityMaster`;

  constructor(
    private _http: HttpClient,
    router: Router,
    spinner: NgxSpinnerService
  ) {
    super(_http, router, spinner);
  }
  /*  Master List API */
  getTeamMasterForGrid(model: any): Observable<any> {
    return this.post(`${this.teamUrl}/GetTeamMasterForGrid/`, model);
  }

  getRoleMasterForGrid(model: any): Observable<any> {
    return this.post(`${this.roleUrl}/GetRoleMasterForGrid/`, model);
  }

  getDepartmentMasterForGrid(model: any): Observable<any> {
    return this.post(`${this.commonUrl}/GetDepartmentMasterForGrid/`, model);
  }
  getDepartmentList(params: any) {
    return this.postParams(`${this.CommonUrl}/GetDepartmentMasterList`, "", params);
  }
  AddUpdateDepartmentMasterId(model: any) {
    return this.post(`${this.CommonUrl}/AddUpdateDepartmentMasterId`, model);
  }
  /*  Master List API end*/

  getDataForDropdownList(dropdownListRequestModel: DropdownListRequestModel): Observable<any> {
    return this.post(`${this.CommonUrl}/GetDataForDropdownList/`, dropdownListRequestModel);
  }
}
