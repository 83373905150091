import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { CoreHttpService } from 'src/app/providers/core-http/core-http.service';
import { environment } from 'src/environments/environment';
import { RequestForVOB } from './cencus.classes';


@Injectable({
  providedIn: 'root'
})
export class CensusService extends CoreHttpService {
  private censusUrl: string = `${environment.apiUrl}/CaseRegister`;

  constructor(
    private _http: HttpClient,
    router: Router,
    spinner: NgxSpinnerService
  ) {
    super(_http, router, spinner);
  }

  getCensusFacilityCount(data: any) {
    return this.post(`${this.censusUrl}/GetCensusFacilityCount`, data);
  }

  getCensusDetailsByFacilityId(data: any) {
    return this.post(`${this.censusUrl}/GetCensusDetailsByFacilityId`, data);
  }

  updateRequestforVob(model: RequestForVOB): Observable<any> {
    return this.put(`${this.censusUrl}/UpdateRequestForVOB`, model);
  }

  updateRequestforUR(model): Observable<any> {
    return this.put(`${this.censusUrl}/UpdateRequestForAuthorization`, model);
  }
  getPatientByFacilityId(params: any) {
    return this.postParams(`${this.censusUrl}/GetPatientByFacilityId`, "", params );
  }
  getMonthYearList(facilityIdsData:any=0) {
    return this.post(`${this.censusUrl}/GetMonthYearList?facilityIds=${facilityIdsData}`, "");
  }
  getCensusFacilitiesWithGroup(data: any): Observable<any> {
    return this.post(`${this.censusUrl}/GetCensusFacilitiesWithGroup`, data);
  }
  addUpdateCaseRegister(data: any) {
    return this.post(`${this.censusUrl}/AddUpdateCaseRegister`, data);
  }
  addUpdateCaseRegisterTrans(data: any) {
    return this.post(`${this.censusUrl}/AddUpdateCaseRegisterTrans`, data);
  }
  updatePatientServiceByCaseId(data: any) {
    return this.post(`${this.censusUrl}/UpdatePatientServiceByCaseId`, data);
  }

}
