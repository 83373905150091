import { Component, OnInit, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { CoreHelperService } from '../../../providers/core-helper/core-helper.service';
import { FormBuilder, Validators, } from '@angular/forms';
import * as moment from 'moment';
import { SortDescriptor, State } from "@progress/kendo-data-query";
import { DataStateChangeEvent } from "@progress/kendo-angular-grid";
import { UsernameValidator } from 'src/app/shared/CommonValidation/username.validator';
import { UserDomainType } from 'src/app/common/enum/user-domain';

export class CereusNotesAddUpdateEvent {
    id: number;
    text: string;
    isExternal: number;
    spMode: number;
}

export enum CereusNotesMode {
    ExternalInternal = 0,
    DomainOnly = 1
}

export enum CeruesNotesExternalAddMode {
    Text = 0,
    Selection = 1
}

@Component({
    selector: 'app-cereus-notes',
    templateUrl: './cereus-notes.component.html'
})

export class CereusNotesComponent implements OnInit {
    @Input() internalNotes: any[] = [];
    @Input() externalNotes: any[] = [];
    @Input() historyList: any[] = [];
    @Input() mode: CereusNotesMode = CereusNotesMode.ExternalInternal;
    @Input() externalAddMode: CeruesNotesExternalAddMode = CeruesNotesExternalAddMode.Text;
    @Input() externalAddSelectOptions: any[] = [];
    @Input() showAddButton: boolean = false;
    @Output() addUpdateNote = new EventEmitter<CereusNotesAddUpdateEvent>();

    InternalNoteTabId: number = 0;
    ExternalNoteTabId: number = 1;
    HistoryTabId: number = 2;
    
    editDialog = {
        title: 'Notes',
        isOpen: false,
        isSubmitted: false,
        formGroup: this.fb.group({
            noteId: [],
            noteText: ['', [Validators.required, Validators.maxLength(450), UsernameValidator.cannotContainSpace]],
            noteExternalSelect: ['', [Validators.required, Validators.maxLength(450), UsernameValidator.cannotContainSpace]],
        })
    }

    showCtrl: boolean;
    currentUserDomain: number;
    currentUserId: number;
    currentTab: number;

    noteList: any[] = [];
    sort: SortDescriptor[] = [];

    state: State = {
        skip: 0,
        take: 10,
    };

    externalSelectFilterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains',
    };

    constructor(
        public _coreHelper: CoreHelperService,
        private dialogService: DialogService,
        private fb: FormBuilder
    ) {
    }

    async ngOnInit() {
        this.currentUserDomain = this._coreHelper.getLoggedInUserDomain();
        this.currentUserId = this._coreHelper.getUserId();
        this.showCtrl = (this.currentUserDomain === UserDomainType.External || this.currentUserDomain === UserDomainType.Internal);
        this.changeTab(0);
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((this.currentTab === 0 && changes['internalNotes'] !== undefined)
            || (this.currentTab === 1 && changes['externalNotes'] !== undefined)) {
            this.changeTab(this.currentTab);
        }
    }

    public dataStateChange(state: DataStateChangeEvent): void {
        this.state = state;
    }

    //#region -- SHOW ADD NOTE POPUP
    openDialog(noteId: number) {
        const noteType = this.currentTab === 0 ? 'Internal' : 'External';
        this.editDialog.title = noteId > 0 ? `Edit ${noteType} Note` : `Add ${noteType} Note`;
        this.editDialog.isSubmitted = false;
        let noteTxt = '';
        if (noteId > 0) {
            const filtered = this.noteList.filter(x => x.notesId === noteId);

            if (filtered && filtered.length > 0) {
                noteTxt = filtered[0].userNotes;
            }
        }
        this.editDialog.formGroup.patchValue({
            noteId: noteId,
            noteText: noteTxt,
            noteExternalSelect: noteTxt
        });
        this.editDialog.isOpen = true;
    }

    submitDialog() {
        this.editDialog.isSubmitted = true;
        let noteTxt = '';

        if (this.externalAddMode === 0 || this.currentTab !== 1) {

            if (this.editDialog.formGroup.get('noteText').errors) {
                return;
            }
            noteTxt = this.editDialog.formGroup.value.noteText;
        } else if (this.externalAddMode === 1 && this.currentTab === 1) {

            if (this.editDialog.formGroup.get('noteExternalSelect').errors) {
                return;
            }
            noteTxt = this.editDialog.formGroup.value.noteExternalSelect;
        }

        this.addUpdateNote.emit({
            id: this.editDialog.formGroup.value.noteId,
            text: noteTxt,
            isExternal: (this.currentTab === 1) ? 1 : 0,
            spMode: (this.editDialog.formGroup.value.noteId === 0) ? 0 : 1
        });

        this.editDialog.isOpen = false;
    }
    //#endregion

    //#region -- DELETE SELECTED NOTE
    deleteNote(noteId: number) {
        if (noteId <= 0) {
            return;
        }
        const dialog: DialogRef = this.dialogService.open({
            cssClass: "kendo-modal modal-sm text-center",
            title: 'Please Confirm',
            content: 'Are you sure you want to delete?',
            actions: [{ text: "Yes", IsDelete: true, themeColor: "primary" }, { text: "No", IsDelete: false }],
        });

        dialog.result.subscribe((result) => {
            if (!(result instanceof DialogCloseResult) && result.text === 'Yes') {
                this.addUpdateNote.emit({
                    id: noteId,
                    text: '',
                    isExternal: (this.currentTab === 1) ? 1 : 0,
                    spMode: 2
                });
            }
        });
    }
    //#endregion

    changeTab(tabIndex: number) {
        if (tabIndex === 0) {
            // external user or ExternalOnly; no access to internal notes
            if (this.currentUserDomain === UserDomainType.External) {
                this.changeTab(1);
            }

            // reset properties each time list loads since they are time dependent
            this.noteList = this.addPropertiesToNotes(this.internalNotes);
        } else if (tabIndex === 1) {
            if (this.mode === CereusNotesMode.DomainOnly
                && this.currentUserDomain === UserDomainType.Internal) {
                this.changeTab(0);
            }
            this.noteList = this.addPropertiesToNotes(this.externalNotes);
        }
        this.currentTab = tabIndex;
    }

    // use properties instead of methods; methods are repeatedly called angular; properties aren't
    addPropertiesToNotes(list: any[]) {
        for (let i = 0; i < list.length; i++) {
            list[i]['canModify'] = this.canModifyNote(list[i]);
        }
        return list;
    }

    canModifyNote(item: any): boolean {
        if (this.currentUserId !== item.updatedById
            || this._coreHelper.isNullOrUndefined(item.updatedDate)) {
            return false;
        }
        const nowLocal = new Date();
        // updated within last 5 minutes
        // api dates are UTC; Date class using local time; add offset (- 5 minutes) 
        let dateToCompare = moment(nowLocal).add(nowLocal.getTimezoneOffset() - 5, 'minutes').format('YYYY-MM-DD  HH:mm:ss');
        let recDate = moment(item.updatedDate).format('YYYY-MM-DD  HH:mm:ss');;
        return moment(recDate).isAfter(moment(dateToCompare))
    }

    onExternalSelectIndexChanged(event) {
        this.editDialog.formGroup.value.noteExternalSelect = event.value;
    }
}