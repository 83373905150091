import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { CoreHttpService } from 'src/app/providers/core-http/core-http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CollectionService extends CoreHttpService {
  isFromBack : boolean = false;
  isProgressTab : boolean = false;
  selectedCard : number = 0;
  public filter = false;
  addFilterOpen(){
    this.filter = true;
  }
  filterClose(){
    this.filter = false;
  }

  private rcmUrl: string = `${environment.apiUrl}/RCM`;
  private statisticUrl: string = `${environment.apiUrl}/Statistics`;

  constructor(
    private _http: HttpClient,
    router: Router,
    spinner: NgxSpinnerService
  ) {
    super(_http, router, spinner);
  }

  getRCMWorkSpaceData(model: any) {
    return this.post(`${this.rcmUrl}/GetRCMWorkspaceData`, model);
  }

  getRCMProgressData(model: any) {
    return this.post(`${this.rcmUrl}/GetRCMProgressData`, model);
  }

  getRCMCaseDetailsByCaseId(caseId: number): Observable<any> {
    return this.get(`${this.rcmUrl}/GetRCMCaseDetailsByCaseId?caseId=${caseId}`);
  }

  getRcmServiceListByRcmId(rcmMasterId: number , patientId: number,statusId : number): Observable<any> {
    return this.get(`${this.rcmUrl}/GetRcmServiceListByRcmIdAndPatientId?rcmMasterId=${rcmMasterId}&patientId=${patientId}&statusId=${statusId}`);
  }

  updateRequsetForRCM(model: any): Observable<any> {
    return this.post(`${this.rcmUrl}/UpdateRequestForRCM`, model);
  }

  getRCMHistoryByRequestId(requestId: any): Observable<any> {
    return this.get(`${this.rcmUrl}/GetRCMHistoryByRequestId?requestId=${requestId}`);
  }

  getRCMIssueStatus(optionId: number) : Observable<any> {
    return this.get(`${this.rcmUrl}/GetRCMIssueStatuses?optionId=${optionId}`)
  }

  updateMedicalRecordsRequestFromRCM(model: any): Observable<any> {
    return this.post(`${this.rcmUrl}/UpdateMedicalRecordRequestFromRCM`, model)
  }
  GetRCMWorkspaceChartData(statusId: number, priorityId: number, facilityId: number): Observable<any> {
    return this.get(`${this.rcmUrl}/GetRCMWorkspaceChartData?facilityId=${facilityId}&priorityId=${priorityId}&statusId=${statusId}`);
  }

  updateRequestRCMForPayments(model: FormData) {
    return this.postFormData(`${environment.apiUrl}/Payment/UpdateRequestRCMForPayments`, model);
  }
  getRcmStatistics(params) {
    return this.post(`${this.statisticUrl}/GetRcmStatistics`,params);
  }
  GetRcmScoreCard(params) {
    return this.post(`${this.statisticUrl}/GetRcmScoreCard`,params);
  }
  getRcmTeamPerfomance(facilityId: number, statusId: number): Observable<any> {
    return this.get(`${this.statisticUrl}/GetRcmTenureGoalsReport?facilityId=${facilityId}&statusId=${statusId}`);
  }

  getRCMSentTopaymentData(facilityId: number, rcmTransIds: string) : Observable<any> {
    return this.get(`${this.rcmUrl}/GetRcmSentToPaymentData?facilityId=${facilityId}&rcmTransIds=${rcmTransIds}`)
  }

}
