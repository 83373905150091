import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { CoreHttpService } from 'src/app/providers/core-http/core-http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class documentsService extends CoreHttpService {

  private taskUrl: string = `${environment.apiUrl}/TaskMaster`;
  private documentUrl: string = `${environment.apiUrl}/DocumentMaster`;
  private facilityDocumentUrl: string = `${environment.apiUrl}/FacilityDocuments`

  constructor(
    private _http: HttpClient,
    router: Router,
    spinner: NgxSpinnerService
  ) {
    super(_http, router, spinner);
  }
  /* Task Master API */
  gettaskManagementList(params: any): Observable<any> {
    return this.postParams(`${this.taskUrl}/GetTaskListByFacilityAssignedToOrDepartment`, "", params);
  }
  getEditedTaskList(params: any): Observable<any> {
    return this.postParams(`${this.taskUrl}/GetTaskByTaskId`, "", params);
  }
  getownTaskManagementList(params: any): Observable<any> {
    return this.postParams(`${this.taskUrl}/GetNotificationListByUserId`, "", params);
  }

  deleteTaskMaster(formData: FormData): Observable<any> {
    return this.postFormData(`${this.taskUrl}/AddUpdateTaskMaster`, formData);
  }

  /* Document Master API */
  documentType(param: any): Observable<any> {
    return this.post(`${this.documentUrl}/GetDocumentTypeId`, param);
  }

  documentCategory(param: any): Observable<any> {
    return this.post(`${this.documentUrl}/GetDocumentCategory`, param);
  }

  documentSubCategory(param: any): Observable<any> {
    return this.postParams(`${this.documentUrl}/GetDocumentSubCategory`, "", param);
  }

  addUpdateDocumentCategory(model: any): Observable<any> {
    return this.post(`${this.documentUrl}/AddUpdateDocumentCategory`, model)
  }

  addUpdateDocumentSubCategory(model: any): Observable<any> {
    return this.post(`${this.documentUrl}/AddUpdateDocumentSubCategory`, model)
  }

  addUpdateDocument(model: FormData): Observable<any> {
    return this.postFormData(`${this.documentUrl}/AddUpdateDocumentMaster`, model)
  }

  getDocumentList(param: any): Observable<any> {
    return this.postParams(`${this.documentUrl}/GetDocumentList`, "", param);
  }

  documentHistory(param: any): Observable<any> {
    return this.postParams(`${this.documentUrl}/GetDocumentHistoryByDocumentId`, "", param)
  }

  assignDocumentToUser(param: any): Observable<any> {
    return this.postParams(`${this.documentUrl}/DocumentAssignToUsers`, "", param)
  }

  //Facility Master -> Documents Tab Data
  getFacilityMasterDocumentList(params: any): Observable<any> {
    return this.get(`${this.facilityDocumentUrl}/GetFacilityMasterDocumentList?facilityId=${params}`);
  }

  downloadDocumentById(documentId: number): Observable<any> {
    return this.get(`${this.documentUrl}/DownloadDocumentMasterById?documentID=${documentId}`);
  }

  archiveDocumentById(documentId: number, isArchive: number): Observable<any> {
    return this.get(`${this.documentUrl}/ArchiveDocumentById?documentId=${documentId}&isArchive=${isArchive}`);
  }

  deleteDocumentById(documentId: number): Observable<any> {
    return this.get(`${this.documentUrl}/DeleteDocumentById?documentId=${documentId}`);
  }

  getDocumentcategoryList(documentTypeId: number, categoryId: number = 0): Observable<any> {
    return this.get(`${this.documentUrl}/GetDocumentCategoryList?documentTypeId=${documentTypeId}&categotyId=${categoryId}`);
  }

  //Patient Master Document Tab
  GetDocumentMasterForGrid(model: any) {
    return this.post(`${this.documentUrl}/GetDocumentMasterForGrid`, model);
  }
}
