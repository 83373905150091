<div class="vob-information" [ngStyle]="{'visibility':showCtrl ? 'visible' : 'hidden'}">
    <div class="vob-info-actions">
        <div class="info-title">
            <h6>Notes</h6>
        </div>
        <div ngClass="info-action">
            <div class="action-box" *ngIf="showAddButton && currentTab <= 1">
                <a href="javascript:;" class="btn btn-primary" (click)="openDialog(0);"><span class="custom-icons icon-plus me-1"></span> Add</a>
            </div>
        </div>
    </div>
    <div class="common-white-box vob-note-height-btn-title">
        <ul class="nav nav-tabs common-nav-tab note-mr-btm inner-square-nav-tav" id="NoteTab" role="tablist">
            <li class="nav-item" role="presentation" [ngStyle]="{'display': mode === 1 && currentUserDomain !== 0 ? 'none' : 'inline-block'}">
                <button class="nav-link w-100" [ngClass]="{'active': currentTab === this.InternalNoteTabId}"
                    id="Internal-tab" data-bs-toggle="tab" (click)="changeTab(0)" 
                    type="button" role="tab" aria-controls="Internal" aria-selected="true">
                    Internal
                </button>
            </li>
            <li class="nav-item" role="presentation" [ngStyle]="{'display': mode === 1 && currentUserDomain !== 1 ? 'none' : 'inline-block'}">
                <button class="nav-link w-100" [ngClass]="{'active': currentTab === this.ExternalNoteTabId}"
                    id="External-tab" data-bs-toggle="tab" (click)="changeTab(1)"
                    type="button" role="tab" aria-controls="External" aria-selected="false">
                    External
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link w-100" id="History-tab" data-bs-toggle="tab" [ngClass]="{'active': currentTab === this.HistoryTabId}"
                    (click)="changeTab(2)" type="button" role="tab" aria-controls="History"
                    aria-selected="false">
                    History
                </button>
            </li>
        </ul>
        <div class="tab-content notes-height">
            <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="Internal-tab" *ngIf="this.currentTab <= 1">
                <div *ngFor="let item of noteList">
                    <div class="vob-note-box" *ngIf="item.userNotes !=''" style="border-bottom: 1px solid #E5EDEF; margin-bottom: 11px; padding-bottom: 14px;">
                        <p>{{item.userNotes}}</p>
                        <span class="date-time-text">
                            {{item.updatedBy}} {{
                            item.updatedDate |
                            localDateTime}}
                        </span>
                        <div class="dropdown table-dropdown note-action-dropdown"
                            *ngIf="item.canModify">
                            <a href="javascript:;" class="dropdown-toggle filter-icon" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="custom-icons icon-list-menu"></i>
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <a class="dropdown-item" href="javascript:;" (click)="openDialog(item.notesId)"><span class="custom-icons icon-pencil"></span>Edit</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="javascript:;" (click)="deleteNote(item.notesId)">
                                        <span class="custom-icons icon-trash"></span>Delete</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="History-tab"  *ngIf="this.currentTab === 2">
                <div class="common-border table-responsive">
                    <kendo-grid [data]="historyList" [pageSize]="state.take!" [skip]="state.skip!"
                        [sort]="state.sort!" [sortable]="false" [pageable]="false" filterable="menu" (dataStateChange)="dataStateChange($event)">
                        <kendo-grid-column field="actionDetails" title="Details" [filterable]="false" [width]="250">
                        </kendo-grid-column>
                        <kendo-grid-column field="actionByUser" title="By User" [filterable]="false" [width]="180">
                        </kendo-grid-column>
                        <kendo-grid-column field="actionDate" title="Date" [filterable]="false" [width]="180"
                            filter="date" format="{0:dd/MM/yyyy}">
                            <ng-template kendoGridCellTemplate let-dataItem>
                            <span>{{dataItem.actionDate | localDateTime}}</span>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Add Notes Start -->
<kendo-dialog title="{{editDialog.title}}" *ngIf="editDialog.isOpen" (close)="editDialog.isOpen = false;" class="kendo-modal modal-md">
    <div class="common-popup-space">
      <form [formGroup]="editDialog.formGroup" (ngSubmit)="submitDialog()" autocomplete="off">
        <div class="row">
          <div class="col-12 col-md-12">
            <div class="form-group">
                <label class="form-label">Note <span class="field-required">*</span></label>
                <div *ngIf="externalAddMode === 1 && currentTab === 1">
                    <kendo-dropdownlist [data]="externalAddSelectOptions" maxlength="450"
                        formControlName="noteExternalSelect" [defaultItem]="{  text: 'Select note', value: null }"
                        [valuePrimitive]="true" [kendoDropDownFilter]="externalSelectFilterSettings"
                        [ngClass]="{ 'is-invalid': editDialog.formGroup.get('noteExternalSelect').errors && editDialog.isSubmitted}"
                        textField="text" valueField="value" (selectionChange)="onExternalSelectIndexChanged($event)">
                    </kendo-dropdownlist>
                    <div *ngIf="editDialog.formGroup.get('noteExternalSelect').errors && editDialog.isSubmitted" class="invalid-feedback">
                        Please select note
                    </div>
                </div>
                <div *ngIf="externalAddMode === 0 || currentTab !== 1">
                    <textarea class="form-control" formControlName="noteText" placeholder="Note" maxlength="450" 
                        [ngClass]="{ 'is-invalid': editDialog.formGroup.get('noteText').errors && editDialog.isSubmitted}"></textarea>
                    <div *ngIf="editDialog.formGroup.get('noteText').errors && editDialog.isSubmitted" class="invalid-feedback">Please enter a note</div>
                </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <kendo-dialog-actions class="d-block text-end">
      <button kendoButton (click)="submitDialog()" class="btn btn-primary btn-md">Save</button>
      <button kendoButton (click)="editDialog.isOpen = false;" class="btn btn-cancel btn-md">Cancel</button>
    </kendo-dialog-actions>
</kendo-dialog>
<!-- Add Notes Popup End -->