import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { CoreHttpService } from 'src/app/providers/core-http/core-http.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ReportService extends CoreHttpService {
  public todate = new Date();
  private reportUrl: string = `${environment.apiUrl}/Reports`;
  private commonUrl: string = `${environment.apiUrl}/Common`;

  constructor(
    private _http: HttpClient,
    router: Router,
    spinner: NgxSpinnerService
  ) {
    super(_http, router, spinner);
  }
  public disabledDates = (date: Date): boolean => {
    date.setDate(date.getDate());
    console.log(this.todate.toString());
    return date > this.todate;
  }
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  getClaimsBilledReport(params): Observable<any> {
    return this.post(`${this.reportUrl}/GetClaimsBilledReport`,params);
  }
  getPaymentSummaryReport(params) {
    return this.post(`${this.reportUrl}/GetPaymentSummaryReport`, params);
  }
  getPaymentDetailReport(params) {
    return this.post(`${this.reportUrl}/GetPaymentDetailsReport`, params);
  }
  getAgingReport(params: any) {
    return this.post(`${this.reportUrl}/GetAgingReport`, params);
  }
  getAgingSummaryReport(params: any) {
    return this.post(`${this.reportUrl}/GetAgingSummaryReport`, params);
  }
  getFacilityForFilter(params): Observable<any> {
    return this.postParams(`${this.commonUrl}/GetFacilityMasterByID`, "", params);
  }
  getReportFacilityListByUser(params: any): Observable<any> {
    return this.postParams(`${this.commonUrl}/GetFacilitiesByUser`, params);
  }
  getURDailyReport(params: any): Observable<any> {
    return this.post(`${this.reportUrl}/GetFacility_URDailyReport`,params);
  }
  getURReviewReport(params: any): Observable<any> {
    return this.post(`${this.reportUrl}/GetFacility_URReviewReport`,params);
  }
  getActivitySummaryByMonth(params: any): Observable<any> {
    return this.post(`${this.reportUrl}/GetActivitySummaryByMonth`,params);
  }
  getTotalBalanceByPayerPriority(params: any): Observable<any> {
    return this.post(`${this.reportUrl}/getTotalBalanceByPayerPriority`,params);
  }
  getPatientBalanceReport(params: any): Observable<any> {
    return this.post(`${this.reportUrl}/GetPatientBalanceReport`,params);
  }
}
